<template>
  <b-card v-if="userList" no-body class="card-company-table">
    <b-table :items="userList" responsive :fields="fields" class="mb-0">
      <template #cell(Email)="data">
        {{ data.item.email }}
      </template>

      <!-- Column: Type -->
      <template #cell(Tipo)="data">
        <div class="text-nowrap">
          <b-badge
            pill
            :variant="`light-${resolveUserStatusVariant(data.item.systemRole)}`"
            class="text-capitalize"
          >
            <span class="align-text-top text-capitalize">{{
              formattedType(data.item.systemRole)
            }}</span>
          </b-badge>
        </div>
      </template>

      <!-- Column: Group -->
      <template #cell(Grupo)="data">
        {{ formattedGroup(data.item.userGroup) }}
      </template>

      <!-- Column: Active -->
      <template #cell(Ativo)="data">
        <b-form-checkbox v-model="data.item.enabled" disabled>
        </b-form-checkbox>
      </template>

      <template #cell(TimeSheet)="data">
        <b-form-checkbox v-model="data.item.timeSheet" disabled>
        </b-form-checkbox>
      </template>
    </b-table>
  </b-card>
</template>

<script>
import {
  BCard,
  BTable,
  BAvatar,
  BImg,
  BFormCheckbox,
  BBadge,
} from "bootstrap-vue";

import URL_API from "@core/utils/env";
import getHeader from "@core/utils/auth";
import axios from "@axios";
import moment from "moment";
import store from "@/store";
export default {
  components: {
    BCard,
    BTable,
    BAvatar,
    BImg,
    BFormCheckbox,
    BBadge,
  },

  data() {
    return {
      userList: [],

      currentUserObj: store.state.user.userData,
      userData: store.state.user.userData,

      fields: [
        { key: "Email", sortable: false, label: this.getColLabel("email") },
        { key: "Tipo", sortable: false, label: this.getColLabel("type") },
        { key: "Grupo", sortable: false, label: this.getColLabel("group") },
        {
          key: "TimeSheet",
          sortable: false,
          label: this.getColLabel("activeTimeSheet"),
        },
        { key: "Ativo", sortable: false, label: this.getColLabel("active") },
      ],
    };
  },

  async created() {
    await this.getData();
  },

  methods: {
    async getData() {
      await axios
        .get(`${URL_API}user/list`, {
          headers: getHeader(this.userData),
        })
        .then((response) => {
          this.userList = response.data.content;
        });
    },

    formattedType(value) {
      if (value == "ROLE_ADMIN") {
        return "Administrador";
      }
      if (value == "ROLE_USER") {
        return "Usuário";
      }
      return "Não infomado";
    },

    resolveUserStatusVariant(status) {
      if (status === "ROLE_ADMIN") return "warning";
      if (status === "ROLE_USER") return "success";
      return "success";
    },

    getColLabel(colName) {
      return this.$i18n.t("message.list." + colName);
    },

    formattedGroup(value) {
      if (value == "accessControlAdministrators") {
        return "Administradores";
      }
      if (value == "accessControlAdministrative") {
        return "Administrativos";
      }
      if (value == "accessControlDirector") {
        return "Diretores";
      }
      if (value == "accessControlManager") {
        return "Gerentes";
      }
      if (value == "accessControlFinancial") {
        return "Financeiros";
      }
      if (value == "accessControlUser") {
        return "Usuários";
      }
      if (value == "accessControlApplication") {
        return "Aplicação";
      }
      if (value == "accessControlCustomer") {
        return "Clientes";
      }
      if (value == "accessControlCommercial") {
        return "Comercial";
      }
      return "Não infomado";
    },

    convertData(data) {
      var fileSizeInKB = 0;
      var fileSizeInMB = 0;

      if (data != undefined && data != null) {
        fileSizeInKB = Number(data) / 1024;
        fileSizeInMB = fileSizeInKB / 1024;
      }

      return fileSizeInMB.toFixed(2);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@core/scss/base/bootstrap-extended/include";
@import "~@core/scss/base/components/variables-dark";

.card-company-table ::v-deep td .b-avatar.badge-light-company {
  .dark-layout & {
    background: $theme-dark-body-bg !important;
  }
}
</style>
